import { envIsProd } from '@/helper/env'
import Request, { MarkcoinRequest } from '@/plugins/request'
import {
  YapiGetV1HomeAppinfoGetListApiRequest,
  YapiGetV1HomeAppinfoGetListApiResponse,
} from '@/typings/yapi/HomeAppinfoGetListV1GetApi'

export const getDownloadApiData: MarkcoinRequest<
  YapiGetV1HomeAppinfoGetListApiRequest,
  YapiGetV1HomeAppinfoGetListApiResponse['data']
> = params => {
  return Request({
    baseURL: envIsProd ? 'https://console-api.tbkplus.com/' : 'https://im-admin-staging.jklkjnqscc.com',
    path: '/v1/openapi/home/appinfo/getApiList',
    method: 'GET',
    params,
  })
}
